/* eslint-disable indent */
/* eslint-disable comma-dangle */
/* eslint-disable no-trailing-spaces */

type StaticImages = {
    readonly [fileName: string]: {
        uri?: string;
    };
}

const imageStaticSourcesByFileName: StaticImages = {
    '9A95BarMg3W96XS3etWNMJ.png': { uri: '/images/9A95BarMg3W96XS3etWNMJ.png' },
'1FJYCLQ3cBpwCMZxyDBsiq.png': { uri: '/images/1FJYCLQ3cBpwCMZxyDBsiq.png' },
'i6EszVKNTg8xnrx1k9Vxvm.jpg': { uri: '/images/i6EszVKNTg8xnrx1k9Vxvm.jpg' },
'mHFWPJwDM85Gchn9WmqNDq.png': { uri: '/images/mHFWPJwDM85Gchn9WmqNDq.png' },
'cD1hwHsJtMXrMYgkkCTiPi.jpg': { uri: '/images/cD1hwHsJtMXrMYgkkCTiPi.jpg' },
'jJ3phSLuj5FBUfxqLuQbvo.png': { uri: '/images/jJ3phSLuj5FBUfxqLuQbvo.png' },
'3gMy7h5JY9FVbKueRN1Rmu.png': { uri: '/images/3gMy7h5JY9FVbKueRN1Rmu.png' },
'hLn9EPMCnAPsXzBhSFREoV.jpg': { uri: '/images/hLn9EPMCnAPsXzBhSFREoV.jpg' }
}

export default imageStaticSourcesByFileName
